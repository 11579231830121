import supabasePromise from '../utils/supabaseClient';
import { SERVER_URL, USER_CARDS_ENDPOINT } from "../utils/constants";
import { Card } from '../types';

export const fetchUserCards = async (): Promise<Card[]> => {
    const supabase = await supabasePromise;
    const session = await supabase.auth.getSession();
    const token = session?.data.session?.access_token;
    if (!token) throw new Error('User is not authenticated');

    const response = await fetch(SERVER_URL + USER_CARDS_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error('Failed to fetch cards: ' + JSON.stringify(errorData));
    }
    return response.json();
};
