import React from 'react'


const LandingHeader: React.FC = () => {
    return (
        <div>
            <header className="fixed w-full z-10 rounded-lg">
                <nav className="bg-white/10 backdrop-blur shadow-lg shadow-gray-200 py-2.5">
                    <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
                        <a href="/" className="flex items-center">
                            <img src="logo.svg" alt="" className="h-16 mr-2" />
                            <span className="self-center text-xl font-semibold whitespace-nowrap text-black">Fluenty</span>
                        </a>
                        <div className="flex items-center lg:order-2">
                            <a href="/signin" className="text-gray-800 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 focus:outline-none">Sign in</a>
                            <a href="/signup" className="text-white bg-primary hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none">Sign Up</a>
                            {/* <button data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="mobile-menu-2" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button> */}
                        </div>
                        <div className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                                <li>
                                    <a href="#features" className="block py-2 px-4 text-gray-700 hover:text-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                        Features
                                    </a>
                                </li>
                                <li>
                                    <a href="#pricing" className="block py-2 px-4 text-gray-700 hover:text-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                        Pricing
                                    </a>
                                </li>
                                <li>
                                    <a href="#faq" className="block py-2 px-4 text-gray-700 hover:text-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                        FAQ
                                    </a>
                                </li>
                                <li>
                                    <a href="#contact" className="block py-2 px-4 text-gray-700 hover:text-purple-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default LandingHeader