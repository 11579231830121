import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoadingIndicator from '../common/LoadingIndicator';
import ErrorAlert from '../common/alerts/ErrorAlert';

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const { isAuthenticated, error } = useAuth();

    if (error) return <ErrorAlert message={error} />;

    if (isAuthenticated === null) {
        return <div className='flex items-center justify-center h-screen w-screen'><LoadingIndicator /></div>;
    }

    return isAuthenticated ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;