import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import GradientBackgroundLight from '../components/styles/GradientBackgroundLight';
import Header from '../components/landing-page/Header';
import Footer from '../components/landing-page/Footer';
import CallToAction from '../components/landing-page/CallToAction';
import FAQ from '../components/landing-page/FAQ';
import Features from '../components/landing-page/Features';
import Hero from '../components/landing-page/Hero';
import FeaturedClients from '../components/landing-page/FeaturedClients';
import Pricing from '../components/landing-page/Pricing';
import TrustStats from '../components/landing-page/TrustStats';
import SiteBanner from '../components/common/SiteBanner';

const LandingPage: React.FC = () => {
    return (
        <ParallaxProvider scrollAxis={"vertical"}>
            <SiteBanner>
                <span>
                    Please note: Initial app loading times may take up to 50 seconds due to server cost constraints — thank you for your patience; we hope this is temporary.
                </span>
            </SiteBanner>
            <GradientBackgroundLight>
                <Header />
                <Hero />
                <FeaturedClients />
                <Features />
                <TrustStats />
                <Pricing />
                <FAQ />
                <CallToAction />
                <Footer />
            </GradientBackgroundLight>
        </ParallaxProvider>
    );
};

export default LandingPage;