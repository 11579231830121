import supabasePromise from '../utils/supabaseClient';

export const signUp = async (email: string, password: string) => {
    const supabase = await supabasePromise;
    const { data, error } = await supabase.auth.signUp({ email, password });
    if (error) throw error;
    return data.user;
};

export const signIn = async (email: string, password: string) => {
    const supabase = await supabasePromise;
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    return data.user;
};

export const signOut = async () => {
    const supabase = await supabasePromise;
    const { error } = await supabase.auth.signOut();
    
    if (localStorage.getItem('supabase_session')) {
        localStorage.removeItem('supabase_session');
    }
    
    if (error) throw error;
};

export const signInWithGoogle = async () => {
    const supabase = await supabasePromise;
    const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
    });
    if (error) throw error;
};

export const signInAsGuest = async () => {
    const supabase = await supabasePromise;
    const { data, error } = await supabase.auth.signInAnonymously()
    if (error || !data) throw error;
    localStorage.setItem('supabase_session', JSON.stringify(data.session));
    return data.user;
}

export const restoreSession = async () => {
    const supabase = await supabasePromise;
    const savedSession = localStorage.getItem('supabase_session');
    if (savedSession) {
        const session = JSON.parse(savedSession);
        await supabase.auth.setSession(session);
        console.log('Guest session restored:');
    }
};