import React, { ReactNode, useState, useEffect, useRef } from 'react';

interface SiteBannerProps {
    children: ReactNode;
}

const SiteBanner: React.FC<SiteBannerProps> = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isRendered, setIsRendered] = useState(true);
    const [bannerHeight, setBannerHeight] = useState(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const handleClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        if (contentRef.current) {
            setBannerHeight(contentRef.current.clientHeight);
        }
    }, [children]);

    useEffect(() => {
        if (!isVisible) {
            setIsRendered(false);
        } else {
            setIsRendered(true);
        }
        return () => { };
    }, [isVisible]);

    if (!isRendered) return null;

    return (
        <div>
            <div
                className={`fixed top-0 left-0 w-full bg-gradient-to-r from-[#ff7e5f] to-[#feb47b]-1/3 text-white shadow-lg z-50 transition-opacity duration-300 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0 max-h-0'
                    }`}
            >
                <div
                    ref={contentRef}
                    className="flex items-center justify-center mx-auto relative max-w-screen-xl px-4 sm:px-6 py-4 sm:py-6"
                >
                    {children}
                    <button
                        onClick={handleClose}
                        className="ml-4 text-2xl md:text-3xl text-white hover:text-white/70 transition-colors duration-300 ease-in-out"
                    >
                        &times;
                    </button>
                </div>
            </div>
            <div className={`transition-all duration-300 ease-in-out`} style={{ height: `${bannerHeight}px` }}></div>
        </div>
    );
};

export default SiteBanner;