import React, { useEffect, useState, useRef, useCallback } from 'react';
import Card from '../language-card/Card';
import { Card as CardTypes } from '../../types';
import { useFetchCards } from '../../hooks/useFetchCards';
import LoadingIndicator from '../common/LoadingIndicator';
import ErrorAlert from '../common/alerts/ErrorAlert';

type VerticalCarouselProps = {
    onCardsLengthChange: (length: number) => void;
    onCardChange: (index: number, card: CardTypes | null) => void;
    onCompositeScoreChange: (score: number | null) => void;
    onCompletionViewChange: (isCompletionViewVisible: boolean) => void;
};

const VerticalCarousel: React.FC<VerticalCarouselProps> = ({ onCardsLengthChange, onCardChange, onCompletionViewChange, onCompositeScoreChange }) => {
    const { cards, loading, error, compositeScore } = useFetchCards();
    const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        onCardsLengthChange(cards.length - 1);
    }, [cards, onCardsLengthChange]);

    useEffect(() => {
        if (currentIndex < cards.length) {
            onCardChange(currentIndex, cards[currentIndex]);
        }
    }, [currentIndex, onCardChange, cards]);

    useEffect(() => {
        onCompositeScoreChange(compositeScore);
    }, [compositeScore, onCompositeScoreChange]);


    const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const index = cardRefs.current.indexOf(entry.target as HTMLDivElement);
                if (index >= 0 && index !== currentIndex) {
                    setCurrentIndex(index);
                }
            }
        });
    }, [currentIndex]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5
        });

        const currentCardRefs = cardRefs.current;

        currentCardRefs.forEach(card => {
            if (card) observer.observe(card);
        });

        return () => {
            currentCardRefs.forEach(card => {
                if (card) observer.unobserve(card);
            });
        };
    }, [cards, handleIntersection]);

    const handleFinishedClick = () => {
        onCompletionViewChange(true);
    };


    if (loading) return <div className='flex items-center justify-center h-screen w-screen'><LoadingIndicator /></div>;
    if (error) return <ErrorAlert message={error} />;

    return (
        <div className="carousel carousel-vertical w-full h-screen space-y-64">
            {cards.map((card, index) => (
                <div
                    key={index}
                    className="carousel-item w-full h-full flex-col items-center"
                    ref={(el) => (cardRefs.current[index] = el)}
                >
                    <Card card={card} onFinishedClick={index === cards.length - 1 ? handleFinishedClick : undefined} />
                </div>
            ))}
        </div>
    );
};

export default VerticalCarousel