import React from 'react';
import Lottie from 'react-lottie';
import InsightsData from '../../assets/lottie/insights.json';
import deepDiveData from '../../assets/lottie/deep-dive.json';
import completionViewBigTrophyData from '../../assets/lottie/completion-card-big-trophy.json';
import completionViewRocketShipData from '../../assets/lottie/completion-card-rocket-ship-orange.json';

type AnimationData = any;

interface LottieProps {
	animationData: AnimationData,
	height?: number,
	width?: number
}

const defaultOptions = (animationData: AnimationData) => ({
	loop: true,
	autoplay: true,
	animationData: animationData,
	isClickToPauseDisabled: true,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
});

const ReusableLottie = ({ animationData, height = 180, width = 150 }: LottieProps) => {
	return (
		<div className="pointer-events-none">
			<Lottie options={defaultOptions(animationData)} height={height} width={width} />
		</div>
	);
};

/* ---------------------------------- Usage --------------------------------- */
export const InsightsLottie = () => <ReusableLottie animationData={InsightsData} height={100} width={190} />;
export const DeepDiveLottie = () => <ReusableLottie animationData={deepDiveData} height={200} width={180} />;
export const CompletionViewBigTrophyLottie = () => <ReusableLottie animationData={completionViewBigTrophyData} height={300} width={300} />;
export const CompletionViewRocketShipLottie = () => <ReusableLottie animationData={completionViewRocketShipData} height={400} width={400} />;