import React from 'react'

type Props = {
    message?: string;
}

const ErrorAlert: React.FC<Props> = ({ message }) => {
    return (
        <div role="alert">
            <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                Error
            </div>
            <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                {message ? <p>{message}</p> : <p>Something went wrong.</p>}
                <p>Please try again later or contact support if the issue persists.</p>
            </div>
        </div>
    )
}

export default ErrorAlert
