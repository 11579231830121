import React, { useRef, useEffect, useState } from 'react';

interface VideoFrameExtractorProps {
    url: string;
    frameTime: number;
}

const VideoFrameExtractor: React.FC<VideoFrameExtractorProps> = ({ url, frameTime }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            video.crossOrigin = 'anonymous';
            video.src = url;
            video.currentTime = frameTime;
            video.onloadeddata = () => {
                const context = canvas.getContext('2d');
                if (context) {
                    context.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const dataUrl = canvas.toDataURL('image/png');
                    setImageSrc(dataUrl);
                }
            };
        }
    }, [url, frameTime]);

    return (
        <div className="w-full h-full">
            <video ref={videoRef} src={url} style={{ display: 'none' }} />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            {imageSrc && <img src={imageSrc} className="w-full h-full object-cover" alt="Up-next" />}
        </div>
    );
};

export default VideoFrameExtractor;