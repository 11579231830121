import React from 'react'
import Marquee from 'react-marquee-slider';

const logos = [
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/facebook.svg", alt: "Facebook", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/disney.svg", alt: "Disney", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/airbnb.svg", alt: "Airbnb", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/apple.svg", alt: "Apple", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/spark.svg", alt: "Spark", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/samsung.svg", alt: "Samsung", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/disney.svg", alt: "Disney", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/quora.svg", alt: "Quora", href: "#" },
    { src: "https://cruip-tutorials.vercel.app/logo-carousel/sass.svg", alt: "Sass", href: "#" },

];

const FeaturedClients: React.FC = () => {
    return (
        <div className="bg-transparent py-8">
            <div className="max-w-screen-xl mx-auto">
                <Marquee
                    velocity={20}
                    scatterRandomly={false}
                    direction="ltr"
                    resetAfterTries={100}
                    onInit={() => null}
                    onFinish={() => null}
                >
                    {logos.map((logo, index) => (
                        <img
                            key={index}
                            src={logo.src}
                            alt={logo.alt}
                            className="h-7 mx-8 transition-transform transform hover:scale-110 invert"

                        />
                    ))}
                </Marquee>
            </div>
        </div>
    );
};

export default FeaturedClients;
