import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const lightThemes = ['light', 'winter'];
const darkThemes = ['dark', 'night'];

const ThemeController = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    const handleThemeChange = (e: any) => {
        const selectedTheme = e.target.value;
        setTheme(selectedTheme);
    };

    return (
        <div className="dropdown dropdown-bottom dropdown-end md:dropdown-hover">
            <div
                tabIndex={0}
                role="button"
                className="btn btn-sm text-sm lg:btn-md mx-2 md:mx-5 text-primary-content bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 hover:from-blue-600 hover:via-purple-600 hover:to-pink-600"
            >
                Theme
                <svg
                    width="12px"
                    height="12px"
                    className="hidden md:inline-block h-2 w-3 fill-current opacity-60"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2048 2048"
                >
                    <path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path>
                </svg>
            </div>
            <ul tabIndex={0} className="dropdown-content bg-base-300 rounded-box z-[1] w-52 p-2 shadow-2xl">
                <li className="font-bold p-2">Light Themes</li>
                {lightThemes.map((themeOption) => (
                    <li key={themeOption}>
                        <label className="flex items-center p-2 cursor-pointer">
                            <input
                                type="radio"
                                name="theme-dropdown"
                                className="radio radio-primary hidden"
                                value={themeOption}
                                onChange={handleThemeChange}
                                checked={theme === themeOption}
                            />
                            <span className={`btn btn-sm ${theme === themeOption ? 'btn-primary' : 'btn-ghost'} w-full`}>
                                {themeOption.charAt(0).toUpperCase() + themeOption.slice(1)}
                            </span>
                        </label>
                    </li>
                ))}

                <li className="font-bold p-2">Dark Themes</li>
                {darkThemes.map((themeOption) => (
                    <li key={themeOption}>
                        <label className="flex items-center p-2 cursor-pointer">
                            <input
                                type="radio"
                                name="theme-dropdown"
                                className="radio radio-primary hidden"
                                value={themeOption}
                                onChange={handleThemeChange}
                                checked={theme === themeOption}
                            />
                            <span className={`btn btn-sm ${theme === themeOption ? 'btn-primary' : 'btn-ghost'} w-full`}>
                                {themeOption.charAt(0).toUpperCase() + themeOption.slice(1)}
                            </span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ThemeController;