import React from 'react';

const FAQ: React.FC = () => {
    return (
        <div id="faq">
            <section className="bg-transparent py-8 lg:py-0">
                <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6">
                    <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl">
                        Got Questions? We've Got Answers!
                    </h2>
                    <div className="max-w-screen-md mx-auto">
                        {accordionItems.map((item, index) => (
                            <div key={index} className="collapse collapse-arrow bg-transparent border-b border-gray-200">
                                <input type="radio" name="faq-accordion" id={`faq-accordion-${index}`} defaultChecked={index === 0} />
                                <div className="collapse-title text-xl font-medium text-gray-900">
                                    {item.title}
                                </div>
                                <div className="collapse-content">
                                    <p className="text-gray-500">{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

const accordionItems = [
    {
        title: 'What makes Fluenty different?',
        content: 'Fluenty is designed to make language learning fun, engaging, and effective. We use a unique blend of interactive video cards, gamification, deep-dives and insights to supercharge your learning journey. Our system helps you learn through context, not rote memorization, so you naturally absorb new vocabulary and grammar.'
    },
    {
        title: 'How does Fluenty work?',
        content: 'Fluenty presents you with a series of interactive cards, each featuring a short video clip in your target language. You\'ll learn new phrases, see them in action, and dive deeper with our insightful explanations and fun deep dives. Our gamified system tracks your progress, awards you points, and motivates you to keep learning every day.'
    },
    {
        title: 'What languages can I learn with Fluenty?',
        content: 'We\'re currently focused on helping you master English (US), but stay tuned for more language options coming soon!'
    },
    {
        title: 'How can I start learning?',
        content: 'It\'s easy! Simply sign up for a free account to start your language learning adventure. You\'ll gain access to our interactive cards and start building your fluency from day one.'
    }
];

export default FAQ;
