import React from 'react'

const CallToAction: React.FC = () => {
    return (
        <div>
            <section className="bg-transparent py-8 lg:py-6">
                <div className="max-w-screen-xl mx-auto lg:py-16 lg:px-6 shadow-xl drop-shadow-xl shadow-primary/30 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
                    <div className="max-w-screen-sm mx-auto text-center">
                        <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 ">Unlock Your Language Journey Today</h2>
                        <p className="mb-6 font-light text-gray-500 md:text-lg">Experience the future of language learning. <br /> Start for free - no credit card required.</p>
                        <a href="/signup" className="inline-flex items-center justify-center w-full px-5 py-2.5 text-sm font-medium text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 rounded-lg sm:w-auto mr-2 mb-2 shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                            Start Learning for Free
                            <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CallToAction;