import React from 'react';

interface CardStatsProps {
    topWordRank?: number;
    overallRank: number;
    languagePairs?: string;
}

const CardStats: React.FC<CardStatsProps> = ({ overallRank }) => {
    return (
        <div className="text-center">
            <div className="stat-title text-sm">Card Rank</div>
            <div className="stat-value text-primary text-base">{overallRank}</div> {/* text-lg == carousel snap scrolls malfunction */}
        </div>
    );
}

export default CardStats;