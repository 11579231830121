import React from 'react';

export const highlightTargetWord = (phrase: string, targetWord: string): React.ReactNode => {
    const parts = phrase.split(new RegExp(`(${targetWord})`, 'gi'));
    return parts.map((part, index) =>
        part.toLowerCase() === targetWord.toLowerCase() ? (
            React.createElement('span', { key: index, className: 'font-bold text-xl text-white highlight highlight-spread-sm after:bg-gradient-to-l after:from-green-400 after:via-primary after:to-green-400' }, part)
        ) : (
            part
        )
    );
};
