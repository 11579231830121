import React, { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { TypeAnimation } from 'react-type-animation';
import { signInAsGuest } from '../../services/authService';
import LoadingIndicator from '../common/LoadingIndicator';


const LandingHero: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const handleGuestSignIn = async () => {
        setError(null);
        setIsSubmitting(true);
        try {
            await signInAsGuest();
            setIsSignedIn(true);
            window.location.href = '/home';
        } catch (error: any) {
            console.error(error);
            setError('Error during guest sign-in. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSignedIn) {
        return <div className='flex items-center justify-center h-screen w-screen'><LoadingIndicator /></div>;
    }

    return (
        <div>
            <section className="bg-transparent">
                <div className="grid max-w-screen-xl px-4 pt-32 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
                    <div className="place-self-center lg:mr-auto lg:col-span-7">
                        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight text-center text-black lg:text-start sm:text-5xl xl:text-6xl">
                            Ready to Actually Speak Another Language? <br />
                            <span className="text-primary">
                                <TypeAnimation
                                    sequence={[
                                        'We Make it Happen.', 2000,
                                        'No More Excuses.', 2000,
                                    ]}
                                    wrapper="span"
                                    cursor={true}
                                    repeat={Infinity}
                                />
                            </span>
                        </h1>
                        <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
                            With Fluenty, language learning isn't just another task — it's an <strong>adventure</strong>. Dive into a <strong>personalized, gamified experience</strong> designed to make you <strong>learn</strong> and <strong>retain</strong> faster, with <strong>interactive tools</strong> that bring the journey to life.
                        </p>
                        <div className="justify-center space-y-4 lg:justify-start sm:flex sm:space-y-0 sm:space-x-4">
                            <button
                                onClick={handleGuestSignIn}
                                className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-purple-600 transition duration-300 ease-in-out transform bg-purple-100 border border-purple-600 rounded-lg shadow-md hover:text-purple-800 hover:bg-purple-200 sm:w-auto hover:-translate-y-1 hover:scale-105 focus:ring-1 focus:ring-primary">
                                {isSubmitting ? (
                                    <span className="loading loading-spinner text-primary"></span>
                                ) : (
                                    <>
                                        Try It Out - No Signup Required
                                        <svg className="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                        </svg>
                                    </>
                                )}
                            </button>
                            {error && <p className="text-center text-sm font-bold text-red-500">{error}</p>}
                        </div>
                    </div>
                    <div className="lg:col-span-5 lg:flex z-11">
                        <Parallax scale={[1, 0.6]} speed={10}>
                            <div className="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-2xl shadow-primary">
                                <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                                <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                                <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                                <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                                <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white">
                                    <img src="mobile-screenshots/localhost_3000_home(iPhone 14 Pro Max).webp" className=" w-[272px] h-[572px] mt-4" alt="Light mode mockup" />
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LandingHero;