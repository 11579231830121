import React from 'react';
import { InsightsLottie } from '../../../lottie/LottiePlayer';
import MarkdownRenderer from '../../../common/MarkdownRenderer';

interface InsightsDropdownProps {
    insights: string | undefined;
}

const InsightsDropdown: React.FC<InsightsDropdownProps> = ({ insights }) => {
    return (
        <div className="dropdown-content card card-compact bg-blue-400 bg-opacity-60 left-1/2 transform -translate-x-1/2">
            <div className="card-body">
                <div className="mx-auto overflow-hidden bg-primary rounded-xl">
                    <div className="w-72 md:w-96">
                        <div className="md:shrink-0">
                            <InsightsLottie />
                        </div>
                        <div className="px-1 py-2 md:p-2 mx-auto text-center">
                            <div className="text-sm font-semibold tracking-wide text-primary-content uppercase">
                                Insights
                            </div>
                            <div className="overflow-auto text-base text-primary-content mt-2 max-h-52 md:max-h-64">
                                {insights && insights.trim() !== '' ? (
                                    <MarkdownRenderer content={insights} />
                                ) : (
                                    <p>No insights available.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightsDropdown;