import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

type ConfettiCannonsProps = {
    trigger: boolean;
}

const ConfettiCannons: React.FC<ConfettiCannonsProps> = ({ trigger }) => {
    useEffect(() => {
        if (!trigger) return;

        const colors = ['#033dfc', '#e3fc03'];
        const end = Date.now() + .7 * 1000;

        const frame = () => {
            confetti({
                particleCount: 2,
                angle: 60,
                spread: 55,
                origin: { x: 0 },
                colors: colors
            });
            confetti({
                particleCount: 2,
                angle: 120,
                spread: 55,
                origin: { x: 1 },
                colors: colors
            });

            if (Date.now() < end) {
                requestAnimationFrame(frame);
            }
        };

        frame();
    }, [trigger]);

    return null;
}

export default ConfettiCannons