import React, { useState } from 'react';
import GradientBackgroundLight from '../components/styles/GradientBackgroundLight';
import { signInAsGuest, signIn, signInWithGoogle } from '../services/authService';
import { ReactComponent as Logo } from '../assets/logo/logo.svg';
import EyeIcon from '../components/common/EyeIcon';
import LoadingIndicator from '../components/common/LoadingIndicator';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);


    const handleEmailSignIn = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setIsSubmitting(true);
        try {
            await signIn(email, password);
            setIsSignedIn(true);
            window.location.href = '/home';
        } catch (error: any) {
            console.error(error);
            switch (error.code) {
                case 'invalid_credentials':
                    setError('Invalid email or password. Please try again.');
                    break;
                case 'email_not_confirmed':
                    setError('Please confirm your email before signing in.');
                    break;
                case 'user_not_found':
                    setError('No account found with this email. Please sign up.');
                    break;
                case 'user_banned':
                    setError('Your account has been banned. Please contact support.');
                    break;
                case 'over_request_rate_limit':
                    setError('Too many requests. Please try again later.');
                    break;
                case 'network_error':
                    setError('Network error. Please check your connection and try again.');
                    break;
                default:
                    setError('An error occurred. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleGoogleSignIn = async () => {
        setError(null);
        setIsSubmitting(true);
        try {
            await signInWithGoogle();
        } catch (error: any) {
            setError('Google sign-in failed. Please try again.');
        } finally {
            setIsSubmitting(false)
        }
    };

    const handleGuestSignIn = async () => {
        setError(null);
        setIsSubmitting(true);
        try {
            await signInAsGuest();
            setIsSignedIn(true);
            window.location.href = '/home';
        } catch (error: any) {
            console.error(error);
            setError('Error during guest sign-in. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };


    if (isSignedIn) {
        return <div className='flex items-center justify-center h-screen w-screen'><LoadingIndicator /></div>;
    }

    return (
        <GradientBackgroundLight>
            <div className="min-h-screen flex items-center justify-center">
                <section className="bg-transparent w-full">
                    <div className="flex flex-col items-center justify-center px-4 py-8 mx-auto sm:px-6 lg:px-8">
                        <a href="/" className="flex items-center space-x-4">
                            <Logo className="max-w-[4rem] md:max-w-[5rem]" />
                            <span className="text-3xl font-semibold whitespace-nowrap text-black">Fluenty</span>
                        </a>
                        <div className="w-full bg-white rounded-lg sm:max-w-md shadow-xl shadow-primary/30 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
                            <div className="space-y-4 sm:space-y-6">
                                <h1 className="text-lg sm:text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                    Sign in to your account
                                </h1>
                                {/* Guest Sign-In Button */}
                                <button
                                    onClick={handleGuestSignIn}
                                    className="w-full bg-gradient-to-r from-yellow-400 to-yellow-500 text-black font-bold text-md py-3 px-6 rounded-lg flex items-center justify-center shadow-md hover:shadow-lg hover:bg-gradient-to-r hover:from-yellow-500 hover:to-yellow-600 transition duration-300 transform hover:scale-105 focus:ring-4 focus:ring-yellow-300 focus:outline-none"
                                >
                                    Continue as Guest
                                </button>
                                <div className="divider divider-primary"> or </div>
                                {/* Google Sign-In Button */}
                                <button
                                    onClick={handleGoogleSignIn}
                                    className="w-full bg-[#4285F4] text-white font-medium py-2 px-4 rounded-lg flex items-center justify-center space-x-2 shadow-md hover:bg-[#357ae8] transition duration-300"
                                >
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google logo" className="bg-white rounded-full p-1 w-5 h-5" />
                                    <span className="font-medium">Continue with Google</span>
                                </button>
                                <div className="divider divider-primary"> or </div>
                                <form className="space-y-4 sm:space-y-6" action="#" onSubmit={handleEmailSignIn}>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="bg-white border border-primary text-gray-900 rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
                                            placeholder="Email"
                                            required={true}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                        <div className="relative">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                id="password"
                                                placeholder="••••••••"
                                                className="bg-white border border-primary text-gray-900 rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 pr-8"
                                                required={true}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <button
                                                type="button"
                                                className="absolute inset-y-0 right-0 px-3 flex items-center text-sm leading-5"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                <EyeIcon active={showPassword} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="remember"
                                                    aria-describedby="remember"
                                                    type="checkbox"
                                                    className="w-4 h-4 border border-primary rounded bg-white focus:ring-3 focus:ring-primary"
                                                    required={false}
                                                />
                                            </div>
                                            <div className="ml-3 text-sm"> {/* TODO: Pending */}
                                                <label htmlFor="remember" className="text-gray-500">Remember me</label>
                                            </div>
                                        </div>
                                        <a href="#" className="text-sm font-medium text-primary hover:underline">Forgot password?</a>
                                    </div>
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-primary hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <span className="loading loading-spinner text-primary"></span> : 'Sign in'}
                                    </button>
                                    {error && <p className="text-center text-sm font-bold text-red-500">{error}</p>}
                                    <p className="text-sm font-light text-gray-500">
                                        Don't have an account yet? <a href="/signup" className="font-medium text-primary hover:underline">Sign up</a>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </GradientBackgroundLight>
    );
};

export default SignIn;
