import React from 'react';
import { Parallax } from 'react-scroll-parallax';

const Features: React.FC = () => {
    return (
        <div id="features">
            <section className="bg-transparent py-8 lg:py-0">
                <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
                    {/* Row 1 */}
                    <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 shadow-xl shadow-primary/30 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
                        <div className="text-gray-500 sm:text-lg lg:order-1 text-center lg:text-left">
                            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
                                Unlock Deeper Understanding with <span className="text-primary">Fluenty's Deep Dives</span>
                            </h2>
                            <p className="mb-8 font-light lg:text-xl">
                                Ditch the textbook! Fluenty Deep Dives provide <strong>captivating stories and explanations</strong> that bring new language to life. Uncover cultural nuances, master tricky grammar points, and solidify your understanding in a way that's both <strong>fun</strong> and <strong>effective</strong>.
                            </p>
                            <ul className="pt-8 space-y-5 border-t-2 border-primary my-7 text-center lg:text-left flex flex-col items-center lg:items-start">
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Memorable Stories That Make Language Stick</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Cultural Insights for True Fluency</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Clear and Concise Explanations Tailored to You</span>
                                </li>
                            </ul>
                        </div>

                        {/* Android Mockup Component */}
                        <div className="flex flex-col items-center lg:order-2">
                            <Parallax speed={30} scale={[1, 0.6]}>
                                <div className="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-xl h-[600px] w-[300px] shadow-2xl shadow-primary">
                                    <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                                    <div className="h-[32px] w-[3px] bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                                    <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                                    <div className="rounded-xl overflow-hidden w-[272px] h-[572px] bg-white">
                                        <img
                                            src="mobile-screenshots/DEEPDIVE_localhost_3000_home(iPhone 14 Pro Max).webp"
                                            className="w-[272px] h-[572px] mt-4"
                                            alt="Light mode mockup"
                                        />
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                    </div>

                    {/* Row 2 */}
                    <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 shadow-xl shadow-primary/30 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
                        <div className="text-gray-500 sm:text-lg lg:order-2 text-center lg:text-left">
                            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
                                Unlock <em>Aha!</em> Moments with <span className="text-primary">Fluenty Insights</span>
                            </h2>
                            <p className="mb-8 font-light lg:text-xl">
                                Fluenty Insights provide those "lightbulb" moments that <strong>accelerate your learning</strong>. Get <strong>bite-sized explanations</strong> of key grammar points, cultural quirks, and language nuances – making fluency feel <strong>achievable</strong> and <strong>fun</strong>.
                            </p>
                            <ul className="pt-8 space-y-5 border-t-2 border-primary my-7 text-center lg:text-left flex flex-col items-center lg:items-start">
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Quick and Easy to Digest</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Reinforce Key Learning Points</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Designed for Mastery — Not Memorization</span>
                                </li>
                            </ul>
                        </div>

                        {/* iPhone Mockup Component */}
                        <div className="flex flex-col items-center lg:order-1">
                            <Parallax speed={30} scale={[1, 0.6]}>
                                <div className="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-2xl shadow-primary">
                                    <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                                    <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                                    <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                                    <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white">
                                        <img
                                            src="mobile-screenshots/INSIGHTS_localhost_3000_home(iPhone 14 Pro Max).webp"
                                            className="w-[272px] h-[572px] mt-4"
                                            alt="Light mode mockup"
                                        />
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                    </div>

                    {/* Row 3 */}
                    <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16 shadow-xl shadow-primary/30 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
                        <div className="text-gray-500 my-36 sm:text-lg lg:order-1 text-center lg:text-left">
                            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900">
                                Bring it <span className="text-primary">Anywhere: Desktop or On-the-Go</span>
                            </h2>
                            <p className="mb-8 font-light lg:text-xl">
                                Seamlessly switch between your desktop and mobile devices — your language learning journey with Fluenty moves with you. Dive into Deep Dives, master new phrases, and track your progress, all with the same <strong>engaging experience</strong> across all your devices.
                            </p>
                            <ul className="pt-8 space-y-5 border-t-2 border-primary my-7 text-center lg:text-left flex flex-col items-center lg:items-start">
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Seamless Experience — No Matter Your Device</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Learn Anytime, Anywhere — Life Doesn't Have to Stop</span>
                                </li>
                                <li className="flex space-x-1 justify-center lg:justify-start w-full">
                                    <svg className="flex-shrink-0 w-5 h-5 text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="text-base font-medium leading-tight text-gray-900">Desktop Power, Mobile Freedom — The Best of Both Worlds</span>
                                </li>
                            </ul>
                        </div>

                        {/* Laptop Mockup Component */}
                        <Parallax speed={30} scale={[1.1, 0.7]} className="lg:order-2">
                            {/* Laptop Screen */}
                            <div className="relative mx-auto border-gray-800 bg-gray-800 border-[8px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px] shadow-2xl shadow-primary">
                                <div className="rounded-lg overflow-hidden h-[156px] md:h-[278px] bg-white">
                                    <img
                                        src="desktop-screenshots/INSIGHTS_localhost_3000_home.webp"
                                        className="h-[156px] md:h-[278px] w-full rounded-lg"
                                        alt=""
                                    />
                                </div>
                            </div>
                            {/* Laptop Bottom Bar */}
                            <div className="relative mx-auto bg-gray-900 rounded-b-xl rounded-t-sm h-[17px] max-w-[351px] md:h-[21px] md:max-w-[597px]">
                                <div className="absolute left-1/2 top-0 -translate-x-1/2 rounded-b-xl w-[56px] h-[5px] md:w-[96px] md:h-[8px] bg-gray-800"></div>
                            </div>
                        </Parallax>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default Features;