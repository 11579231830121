import { useEffect, useState } from 'react';
import supabasePromise from '../utils/supabaseClient';
import { restoreSession } from '../services/authService';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await restoreSession();
                const supabase = await supabasePromise;
                const session = await supabase.auth.getSession();
                setIsAuthenticated(!!session?.data.session?.access_token);
            } catch (err) {
                setError('Failed to authenticate.');
                throw err;
            }
        };

        checkAuth();
    }, []);

    return { isAuthenticated, error };
};

export default useAuth;