import React, { useContext } from 'react';
import { ThemeContext } from '../styles/ThemeContext';
import ConfettiCannons from '../common/ConfettiCannons';
import { useFetchUserProgress } from '../../hooks/useFetchUserProgress';
import ErrorAlert from '../common/alerts/ErrorAlert';
import LoadingIndicator from '../common/LoadingIndicator';
import VideoFrameExtractor from '../common/VideoFrameExtractor';
import { toTitleCase } from '../../utils/convertToTitleCase';
import { CompletionViewRocketShipLottie } from '../lottie/LottiePlayer';

type CompletionViewProps = {
    triggerConfetti: boolean;
    compositeScore: number;
};

const CompletionView: React.FC<CompletionViewProps> = ({
    triggerConfetti,
    compositeScore,
}) => {
    const { progress, loading, error } = useFetchUserProgress();
    const { theme } = useContext(ThemeContext);

    const isLightOrWinter = theme === 'light' || theme === 'winter';

    const cardGradient = isLightOrWinter
        ? 'bg-gradient-to-r from-blue-200 to-purple-300'
        : 'bg-gradient-to-r from-indigo-700 via-purple-800 to-pink-900';

    const sneakPeekGradient = isLightOrWinter
        ? 'bg-gradient-to-r from-blue-100 via-teal-200 to-yellow-300 shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-500'
        : 'bg-gradient-to-r from-purple-800 via-pink-700 to-yellow-500 shadow-2xl hover:shadow-2xl hover:scale-105 transition-transform duration-500';

    const keyWordColor = isLightOrWinter ? 'text-indigo-500' : 'text-indigo-300';
    const textColor = isLightOrWinter ? 'text-gray-800' : 'text-white';
    const subtitleColor = isLightOrWinter ? 'text-gray-600' : 'text-gray-300';

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen w-screen">
                <LoadingIndicator />
            </div>
        );
    }

    if (error) {
        return <ErrorAlert message={error} />;
    }

    return (
        <section
            className={`container mx-auto min-h-screen py-6 lg:py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b ${isLightOrWinter ? 'from-gray-100 to-gray-200' : 'from-gray-800 to-gray-900'
                }`}
        >
            {/* Confetti and Completion Message */}
            <div className="relative mb-2">
                <ConfettiCannons trigger={triggerConfetti} />
                <div
                    className={`text-center py-8 px-6 rounded-lg shadow-xl ${isLightOrWinter
                        ? 'bg-gradient-to-r from-blue-400 to-purple-500'
                        : 'bg-gradient-to-r from-indigo-700 via-purple-800 to-pink-900'
                        }`}
                >
                    <div className="py-6 text-3xl md:text-4xl font-bold text-white">
                        <span className="text-yellow-300">Congratulations</span> on Today's Progress!
                    </div>
                    <p className="text-lg md:text-xl text-white opacity-90">
                        You've completed your learning cards for today!
                    </p>
                    <div className="flex justify-center">
                        <CompletionViewRocketShipLottie />
                    </div>
                </div>
            </div>

            {/* Progress Overview */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
                {/* Composite Score Card */}
                <div className={`card ${cardGradient} text-white shadow-lg transform transition duration-500 hover:scale-105`}>
                    <div className="card-body">
                        <div className="flex items-center space-x-4">
                            <div className="avatar placeholder">
                                <div className={`bg-white text-${isLightOrWinter ? 'primary' : 'secondary'} rounded-full w-12 h-12 flex items-center justify-center`}>
                                    <span className="text-2xl">🏆</span>
                                </div>
                            </div>
                            <h2 className={`card-title ${textColor}`}>Composite Score</h2>
                        </div>
                        <p className={`text-3xl font-bold ${textColor}`}>{compositeScore}</p>
                        <p className={`text-sm ${subtitleColor}`}>21% more than last month</p>
                    </div>
                </div>

                {/* Leaderboard Status Card */}
                <div className={`card ${cardGradient} text-white shadow-lg transform transition duration-500 hover:scale-105`}>
                    <div className="card-body">
                        <div className="flex items-center space-x-4">
                            <div className="avatar placeholder">
                                <div className={`bg-white text-${isLightOrWinter ? 'primary' : 'secondary'} rounded-full w-12 h-12 flex items-center justify-center`}>
                                    <span className="text-2xl">🚀</span>
                                </div>
                            </div>
                            <h2 className={`card-title ${textColor}`}>Ahead of Other Learners</h2>
                        </div>
                        <p className={`text-3xl font-bold ${textColor}`}>86%</p>
                        <p className={`text-sm ${subtitleColor}`}>31 cards till next level</p>
                    </div>
                </div>

                {/* Streak Tracker Card */}
                <div className={`card ${cardGradient} text-white shadow-lg transform transition duration-500 hover:scale-105`}>
                    <div className="card-body">
                        <div className="flex items-center space-x-4">
                            <div className="avatar placeholder">
                                <div className={`bg-white text-${isLightOrWinter ? 'red-500' : 'red-400'} rounded-full w-12 h-12 flex items-center justify-center`}>
                                    <span className="text-2xl">🔥</span>
                                </div>
                            </div>
                            <h2 className={`card-title ${textColor}`}>Streak Tracker</h2>
                        </div>
                        <p className={`text-3xl font-bold ${textColor}`}>5-day Streak</p>
                        <progress className={`progress progress-${isLightOrWinter ? 'primary' : 'secondary'} w-full`} value="5" max="10"></progress>
                        <p className={`text-sm ${subtitleColor}`}>Keep up the momentum!</p>
                    </div>
                </div>
            </div>

            {/* Up Next */}
            <div className={`p-6 rounded-lg shadow-2xl mb-12 transform transition duration-500 hover:scale-105 ${sneakPeekGradient}`}>
                <div className="py-6 text-center">
                    <h2 className={`text-3xl font-extrabold drop-shadow-lg bg-clip-text text-transparent ${isLightOrWinter
                        ? 'bg-gradient-to-r from-blue-500 via-indigo-400 to-purple-400'
                        : 'bg-gradient-to-r from-indigo-200 via-purple-200 to-pink-200'
                        }`}>
                        Tomorrow's Content
                    </h2>
                </div>
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {progress?.nextPageContent?.map((item: any) => (
                        <div key={item.target_word} className={`card shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:rotate-1 ${isLightOrWinter ? 'bg-white/15' : 'bg-black/15'}`}>
                            <figure className="w-full h-56 overflow-hidden">
                                <VideoFrameExtractor url={item.video_url} frameTime={1} />
                            </figure>
                            <div className={`card-body rounded-lg ${isLightOrWinter ? 'text-gray-800' : 'text-white'}`}>
                                <h3 className="card-title justify-center">{toTitleCase(item.target_word)}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>




            {/* Additional Section - Personalized Learning Insights */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Learning Insights Card */}
                <div className={`card ${cardGradient} ${isLightOrWinter ? 'text-gray-800' : 'text-white'} shadow-lg p-6 transform transition duration-500 hover:scale-105`}>
                    <h2 className="text-lg text-center font-bold mb-4">
                        🧠 <span className={`${keyWordColor}`}>Your</span> Learning Insights
                    </h2>
                    <ul className="list-disc list-inside space-y-2">
                        <li>
                            <span className="font-semibold">Strongest Area:</span> Vocabulary Building
                        </li>
                        <li>
                            <span className="font-semibold">Needs Improvement:</span> Pronunciation
                        </li>
                        <li>
                            <span className="font-semibold">Next Steps:</span> Spend 10 minutes practicing pronunciation today!
                        </li>
                    </ul>
                </div>

                {/* Continue Learning Call to Action */}
                <div className={`card ${cardGradient} ${isLightOrWinter ? 'text-gray-800' : 'text-white'} shadow-lg p-6 flex flex-col items-center justify-center transform transition duration-500 hover:scale-105`}>
                    <h2 className="text-3xl font-bold mb-4">
                        <span className={`${keyWordColor}`}>Keep</span> Going!
                    </h2>
                    <p className="text-sm mb-4">Continue to the next set of learning cards and keep the momentum going.</p>
                    <button className="btn btn-primary text-lg px-6 py-3">Level Up</button>
                </div>
            </div>
        </section>
    );
};

export default CompletionView;
