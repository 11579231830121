import React from 'react';

interface EyeIconProps {
    active: boolean;
}

const EyeIcon: React.FC<EyeIconProps> = ({ active }) => {
    const fillColor = active ? '#000000' : '#0328fc';

    return (
        <svg
            fill={fillColor}
            viewBox="0 0 14 14"
            role="img"
            focusable="false"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="m 12.861337,6.4922941 c -1.174666,-1.99337 -3.3652895,-3.32564 -5.8613296,-3.32564 -0.5930829,0 -1.1687909,0.0754 -1.717728,0.21685 l -1.0993951,-1.50352 c -0.1583541,-0.22621 -0.470083,-0.28125 -0.6963121,-0.12292 l -0.2732081,0.19121 c -0.2262499,0.15836 -0.2812915,0.47013 -0.1229166,0.69638 l 0.8985619,1.21656 c -1.1254784,0.54981 -2.1509152,1.44467 -2.8497897,2.63004 -2.084e-4,3.5e-4 -4.792e-4,6.9e-4 -7.084e-4,0.001 -0.18260402,0.3099 -0.18674985,0.6985 0,1.0154 0.8020828,1.36108 2.077957,2.41379 3.6012268,2.9447599 L 2.1524273,6.7562741 a 5.6707046,5.6707046 0 0 1 0.5894579,-0.74817 l 2.4874984,3.55375 2.083e-4,0 0.8464578,1.2091699 a 6.8761413,6.8761413 0 0 0 1.6445823,0.0244 L 3.7627179,5.1412641 a 5.7839129,5.7839129 0 0 1 0.8078536,-0.44923 l 5.2465799,7.4279999 c 0.1583541,0.2262 0.4700836,0.28122 0.6963126,0.12291 l 0.273208,-0.19121 c 0.22625,-0.15835 0.281292,-0.47014 0.122916,-0.69637 l -0.898332,-1.21619 -1.46e-4,-1.8e-4 c 1.190853,-0.5828799 2.184061,-1.5008399 2.850207,-2.6312699 a 0.99870768,0.99870768 0 0 0 2e-5,-1.0154 z m -3.7357893,2.38144 -0.3972081,-0.56729 c 0.514208,-0.67848 0.5773122,-1.59069 0.1833333,-2.32667 l 0,6.3e-4 c 0,0.4927 -0.3993748,0.89208 -0.8920828,0.89208 -0.4964163,0 -0.8920827,-0.40311 -0.8920827,-0.89208 0,-0.49271 0.3993747,-0.89209 0.8920827,-0.89209 l 6.25e-4,0 c -0.5156247,-0.27602 -1.1150409,-0.32589 -1.6564572,-0.16 l -0.3972498,-0.56743 c 0.3201248,-0.12536 0.6686663,-0.19423 1.0334993,-0.19423 1.565874,0 2.8333315,1.2672 2.8333315,2.83333 0,0.71883 -0.267354,1.37448 -0.7077912,1.87375 z"></path>
            </g>
        </svg>
    );
};

export default EyeIcon;