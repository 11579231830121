import { useState, useEffect, useCallback } from 'react';
import { fetchUserProgress } from '../services/userProgressService';

export const useFetchUserProgress = () => {
  const [progress, setProgress] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchProgress = useCallback(async () => {
    try {
      const data = await fetchUserProgress();
      setProgress(data);
    } catch (err) {
      setError('Failed to fetch user progress.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProgress();
  }, [fetchProgress]);

  return { progress, loading, error }
};