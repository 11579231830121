import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { SERVER_URL, SUPABASE_CONFIG_ENDPOINT } from "../utils/constants";

let supabase: SupabaseClient | null = null;

const initializeSupabase = async (): Promise<SupabaseClient> => {
    if (supabase) return supabase;

    try {
        console.log('Initializing Supabase client...');
        const response = await fetch(SERVER_URL + SUPABASE_CONFIG_ENDPOINT);
        const data = await response.json();
        supabase = createClient(data.supabaseUrl, data.supabaseAnonKey);
        console.log('Supabase client initialized.');
        return supabase;
    } catch (error) {
        console.error('Error fetching Supabase config:', error);
        throw error;
    }
};

const supabasePromise = initializeSupabase();

export default supabasePromise;