import React from 'react';
import { DeepDiveLottie } from '../../../lottie/LottiePlayer';
import MarkdownRenderer from '../../../common/MarkdownRenderer';

interface DeepDiveModalProps {
    isOpen: boolean;
    onClose: () => void;
    deepDiveContent: string | undefined;
}

export const DeepDiveModal: React.FC<DeepDiveModalProps> = ({ isOpen, onClose, deepDiveContent }) => {
    if (!isOpen) return null;

    return (
        <div>
            <dialog open id="deep-dive-modal" className="modal">
                <div className="modal-box text-center max-h-svh md:max-w-5xl">
                    <div className="text-base md:text-lg font-serif font-black text-primary">Deep Dive</div>
                    <DeepDiveLottie />
                    <div className="py-10">
                        {deepDiveContent && deepDiveContent.trim() !== '' ? (
                            <div className="overflow-auto text-base mt-2">
                                <MarkdownRenderer content={deepDiveContent} />
                            </div>
                        ) : (
                            <p>No deep dive content available.</p>
                        )}
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={onClose}>Close</button>
                </form>
            </dialog>
        </div>
    );
};
