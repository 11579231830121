import React, { useState } from 'react';
import useScreenSize from '../hooks/useScreenSize';
import NavBar from '../components/layout/NavBar';
import DailyCardsProgressBar from '../components/layout/DailyCardsProgressBar';
import VerticalCarousel from '../components/carousel/VerticalCarousel';
import CardStats from '../components/layout/CardStats';
import CompletionView from '../components/completion-view/CompletionView';
import { Card } from '../types/Card';
import { styled } from 'styled-components';
import { ThemeProvider } from '../components/styles/ThemeContext';
import SiteBanner from '../components/common/SiteBanner';

const ScaledContainer = styled.div`
  @media (min-width: 1024px) { /* lg breakpoint */
    zoom: 0.9; /* Scale down to 90% */
  }
`; // This is a temporary fix to scale down the page on large screens

const CompletionViewWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  overflow: auto;
  width: 100%;
  z-index: 10; /* Ensure it's above other content */
`;

const Home: React.FC = () => {
    const isLargeScreen = useScreenSize();
    const [cardsLength, setCardsLength] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [currentCard, setCurrentCard] = useState<Card | null>(null);
    const [showCompletionView, setShowCompletionView] = useState(false);
    const [compositeScore, setCompositeScore] = useState<number | null>(null);

    const handleCardsLengthChange = (length: number) => {
        setCardsLength(length);
    };

    const handleCardChange = (index: number, card: Card | null) => {
        setCurrentCardIndex(index);
        setCurrentCard(card);
    };

    const handleCompositeScoreChange = (score: number | null) => {
        setCompositeScore(score);
    };

    const handleCompletionViewChange = (isCompletionViewVisible: boolean) => {
        setShowCompletionView(isCompletionViewVisible);
    };

    const renderCardStats = () => {
        if (!currentCard || showCompletionView) return null;
        return <CardStats overallRank={currentCard.overall_rank} />;
    };

    return (
        <ThemeProvider>
            <section className={`h-screen ${showCompletionView ? '' : 'overflow-hidden'}`}>
                <ScaledContainer className="flex flex-col">
                    {/* Navbar */}
                    <div className="sticky top-0 z-50 w-full bg-white/10 backdrop-blur shadow-lg shadow-gray-200">
                        <NavBar />
                    </div>

                    {/* Progress Bar and Card Stats (conditional rendering based on screen size) */}
                    {!showCompletionView && (
                        <div className="flex flex-col lg:flex-row lg:justify-center lg:mb-5 items-center mt-4">
                            <div className="flex items-center">
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        className="inline-block h-8 w-8 stroke-current mr-3">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="miter"
                                            strokeWidth="2"
                                            d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                                    </svg>
                                    <DailyCardsProgressBar progress={currentCardIndex > 0 ? (currentCardIndex / cardsLength) * 100 : 0} />
                                </div>
                            </div>
                            {isLargeScreen && (
                                <div className="lg:ml-4">
                                    {renderCardStats()}
                                </div>
                            )}
                        </div>
                    )}

                    {!isLargeScreen && !showCompletionView && (
                        <div className="flex justify-center text-center mt-2 mb-2">
                            {renderCardStats()}
                        </div>
                    )}

                    {/* Main Content - Vertical Carousel or Completion Card */}
                    <section className="flex-grow flex justify-center overflow-hidden">
                        {!showCompletionView ? (
                            <VerticalCarousel
                                onCardsLengthChange={handleCardsLengthChange}
                                onCardChange={handleCardChange}
                                onCompletionViewChange={handleCompletionViewChange}
                                onCompositeScoreChange={handleCompositeScoreChange}
                            />
                        ) : (
                            <CompletionViewWrapper isVisible={showCompletionView}>
                                <CompletionView triggerConfetti={showCompletionView} compositeScore={compositeScore ?? 0} />
                            </CompletionViewWrapper>
                        )}
                    </section>
                </ScaledContainer>
            </section>
        </ThemeProvider>
    );
};

export default Home;
