import React from 'react'

type Props = {
    progress: number;
}

const DailyCardsProgressBar: React.FC<Props> = ({progress}: Props) => {
    return (
        <div>
            <progress
                className="progress progress-primary shadow-md h-1 w-64 sm:w-96 md:w-128 lg:w-144"
                value={progress}
                max="100"
            ></progress>
        </div>
    )
}

export default DailyCardsProgressBar