import React from 'react';

interface GradientBackgroundLightProps {
    children: React.ReactNode;
}

const GradientBackgroundLight: React.FC<GradientBackgroundLightProps> = ({ children }) => {
    return (
        <div className="relative min-h-screen bg-white bg-[radial-gradient(60%_120%_at_50%_50%,hsla(0,0%,100%,0)_0,rgba(252,205,238,.5)_100%)]">
            {children}
        </div>
    );
};

export default GradientBackgroundLight;