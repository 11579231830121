import React from 'react'

const LoadingIndicator: React.FC = () => {
  return (
    <div>
        <span className="loading loading-ring loading-lg text-primary"></span>
    </div>
  )
}

export default LoadingIndicator