import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
    url: string;
    className?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, className }) => {
    const [playing, setPlaying] = useState(false);
    const playerRef = useRef<ReactPlayer>(null);

    const handlePlay = () => {
        setPlaying(true);
    };

    return (
        <div className={`${className} max-h-[30vh] sm:max-h-[40vh] lg:max-h-[45vh] rounded-lg overflow-hidden`}>
            <ReactPlayer
                ref={playerRef}
                url={url}
                playing={playing}
                controls={false}
                playsinline={true}
                style={{objectFit: 'scale-down' }}
                width="100%"
                height="100%"
                onEnded={() => {
                    setPlaying(false);
                }}
            />
            {!playing && (
                <button
                    className="absolute flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2 bg-gray-900 rounded-full opacity-75 top-1/2 left-1/2 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    onClick={handlePlay}
                    style={{ width: '64px', height: '64px' }}
                >
                    <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 5V19L19 12L8 5Z" fill="gray" />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default VideoPlayer;