import React from 'react';
import CardBody from '../language-card/main/CardBody';
import { Card as CardType } from '../../types';

interface CardProps {
    card: CardType;
    onFinishedClick?: () => void;
}

const Card: React.FC<CardProps> = ({ card, onFinishedClick }) => {
    return (
        <section>
            <div>
                <CardBody
                    url={card.video_url}
                    swapOnText={card.translations?.[0]}
                    swapOffText={card.original}
                    targetWord={card.target_word}
                    insights={card.insights?.[0]}
                    deepDiveContent={card.deep_dive?.[0]}
                    onFinishedClick={onFinishedClick}
                />
            </div>
        </section>
    );
};

export default Card;