import { useState, useEffect, useCallback} from 'react';
import { fetchUserCards } from '../services/cardService';
import { Card } from '../types';

export const useFetchCards = () => {
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [compositeScore, setCompositeScore] = useState<number>(0);

  const fetchCards = useCallback(async () => {
    try {
      const data = await fetchUserCards();
      setCards(data);
      const compositeScore = data.reduce((sum, card) => sum + (card.overall_rank || 0), 0);
      setCompositeScore(compositeScore);
    } catch (err) {
      setError('Failed to fetch cards.');
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  return { cards, loading, error, compositeScore };
};