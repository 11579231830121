import React, { useRef, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { highlightTargetWord } from '../../../utils/highlightTargetWord';
import InsightsDropdown from '../insights/dropdown/Insights';
import { DeepDiveModal } from '../deep-dive/modal/DeepDiveModal';

interface CardBodyProps {
    url: string;
    swapOnText: string;
    swapOffText: string;
    targetWord: string;
    insights?: string;
    deepDiveContent?: string;
    onFinishedClick?: () => void;
}

const CardBody: React.FC<CardBodyProps> = ({ url, swapOnText, swapOffText, targetWord, insights, deepDiveContent, onFinishedClick }) => {
    const highlightedText = highlightTargetWord(swapOffText, targetWord);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const insightsButtonRef = useRef<HTMLDivElement>(null);

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    return (
        <div className="flex flex-col">
            <div className="shadow-xl card bg-base-100">
                <figure>
                    <VideoPlayer url={url} className="relative lg:w-2/3" />
                </figure>
                <div className="flex flex-col items-center card-body">
                    <div className="divider divider-primary">
                        <h3 className="text-xl font-semibold">Phrase</h3>
                    </div>
                    <label className="swap text-lg md:text-xl text-center bg-primary text-primary-content rounded-lg px-5 py-4">
                        <input type="checkbox" className="hidden" />
                        <div className="swap-on">{swapOnText}</div>
                        <div className="swap-off">{highlightedText}</div>
                    </label>
                    <div className="flex flex-col items-center card-compact card-actions p-4 badge-primary rounded-xl shadow-2xl shadow-primary mt-4">
                        <div className="flex flex-col items-center space-y-4">
                            <div className="flex space-x-4">
                                <div className="dropdown dropdown-top dropdown-right dropdown-hover">
                                    <div
                                        ref={insightsButtonRef}
                                        className="btn btn-secondary"
                                        role='button'
                                        tabIndex={0}
                                    >
                                        Insights
                                    </div>
                                    <div className="dropdown-content">
                                        <InsightsDropdown insights={insights} />
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-secondary" onClick={toggleModal}>
                                        Deep Dive
                                    </button>
                                </div>
                            </div>
                            {onFinishedClick && (
                                <div className="mt-4">
                                    <button
                                        className="btn btn-secondary border-white btn-wide bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 text-white py-2 px-4 rounded-lg shadow-lg shadow-primary transform transition-transform hover:scale-105"
                                        onClick={onFinishedClick}
                                    >
                                        Finished
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <DeepDiveModal
                        isOpen={isModalOpen}
                        onClose={toggleModal}
                        deepDiveContent={deepDiveContent}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardBody;