import supabasePromise from '../utils/supabaseClient';
import { SERVER_URL, NEXT_CONTENT_API_ENDPOINT } from "../utils/constants";

export const fetchUserProgress = async (): Promise<any> => {
    const supabase = await supabasePromise;
    const session = await supabase.auth.getSession();
    const token = session?.data.session?.access_token;
    if (!token) throw new Error('User is not authenticated');

    const response = await fetch(SERVER_URL + NEXT_CONTENT_API_ENDPOINT, {
        headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error('Failed to fetch user progress: ' + JSON.stringify(errorData));
    }
    return response.json();
};